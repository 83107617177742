export enum FeedbackOptions {
	QUESTION = 'Question',
	COMMENT = 'Comment',
	SUGGESTION = 'Suggestion',
}

export enum FeedbackPlaceholder {
	PH_QUESTION = 'Enter your question',
	PH_COMMENT = 'Enter your comment',
	PH_SUGGESTION = 'Enter your suggestion',
}

export enum SuggestionGridItems {
	SENT_TO_ME = 'Sent to me ',
	MY_SUGGESTIONS = 'My suggestions',
	ARCHIVE = 'Archive',
}

export enum SuggestionArchiveType {
	SENDER = 'Sender',
	RECEIVER = 'Receiver',
}
